import { IFieldCollection } from 'components/DynamicFormComponents/FieldCollection/FieldCollection';
import { FormFieldType } from 'components/DynamicFormComponents/FormField/FormField';
import cloneDeep from 'lodash/cloneDeep';

export enum RegistrationCollection {
  PersonalInfo = 'personalInfo',
  VehicleInfo = 'vehicleInfo',
  PersonalID = 'personalID',
  ClubMemberID = 'clubMemberID',
  FoodOption = 'foodOption',
  GuestsInfo = 'guestsInfo',
  Email = 'email',
  Phone = 'phone',
}

export const personalInfoCollection: IFieldCollection = {
  name: RegistrationCollection.PersonalInfo,
  fields: [
    {
      name: 'firstName',
      type: FormFieldType.TextBox,
      label: 'firstName',
      required: true,
      maxLength: 50,
    },
    {
      name: 'lastName',
      type: FormFieldType.TextBox,
      label: 'lastName',
      required: true,
      maxLength: 50,
    },
  ],
};

export const vehicleInfoCollection: IFieldCollection = {
  name: RegistrationCollection.VehicleInfo,
  fields: [
    {
      name: 'plate',
      type: FormFieldType.TextBox,
      label: 'plate',
      required: true,
      maxLength: 10,
    },
    {
      name: 'make',
      type: FormFieldType.TextBox,
      label: 'make',
      required: true,
      maxLength: 50,
    },
    {
      name: 'model',
      type: FormFieldType.TextBox,
      label: 'model',
      required: true,
      maxLength: 50,
    },
    {
      name: 'year',
      type: FormFieldType.TextBox,
      label: 'year',
      required: true,
    },
  ],
};

export const personalIDCollection: IFieldCollection = {
  name: RegistrationCollection.PersonalID,
  fields: [
    {
      name: 'personalID',
      type: FormFieldType.TextBox,
      label: 'personalID',
      required: true,
      maxLength: 20,
    },
  ],
};

export const clubMemberIDCollection: IFieldCollection = {
  name: RegistrationCollection.ClubMemberID,
  fields: [
    {
      name: 'clubMemberID',
      type: FormFieldType.TextBox,
      label: 'clubMemberID',
      required: true,
      maxLength: 20,
    },
  ],
};

export const foodOptionCollection: IFieldCollection = {
  name: RegistrationCollection.FoodOption,
  mustEditBeforeSubmit: true,
  fields: [
    {
      name: 'foodOption',
      type: FormFieldType.MultipleOptions,
      label: 'foodOption',
      required: true,
      options: [
        { label: 'vegetarian', value: 'vegetarian' },
        { label: 'vegan', value: 'vegan' },
        { label: 'gluten_free', value: 'gluten_free' },
        { label: 'halal', value: 'halal' },
        { label: 'kosher', value: 'kosher' },
      ],
    },
  ],
};

export const guestsInfoCollection: IFieldCollection = {
  name: RegistrationCollection.GuestsInfo,
  fields: [
    {
      name: 'totalGuests',
      type: FormFieldType.TextBox,
      label: 'totalGuests',
      required: true,
    },
    {
      name: 'guestsName',
      type: FormFieldType.Paragraph,
      label: 'guestsName',
      required: true,
    },
  ],
};

export const emailInfoCollection: IFieldCollection = {
  name: RegistrationCollection.Email,
  fields: [
    {
      name: 'email',
      type: FormFieldType.TextBox,
      label: 'email',
      required: true,
      maxLength: 80,
    },
  ],
};

export const phoneInfoCollection: IFieldCollection = {
  name: RegistrationCollection.Phone,
  fields: [
    {
      name: 'phone',
      type: FormFieldType.TextBox,
      label: 'phoneNumber',
      required: true,
      maxLength: 50,
    },
  ],
};

export const getCollectionByEnum = (
  collectionEnum: RegistrationCollection
): IFieldCollection | undefined => {
  switch (collectionEnum) {
    case RegistrationCollection.PersonalInfo:
      return cloneDeep(personalInfoCollection);

    case RegistrationCollection.VehicleInfo:
      return cloneDeep(vehicleInfoCollection);

    case RegistrationCollection.PersonalID:
      return cloneDeep(personalIDCollection);

    case RegistrationCollection.ClubMemberID:
      return cloneDeep(clubMemberIDCollection);

    case RegistrationCollection.FoodOption:
      return cloneDeep(foodOptionCollection);

    case RegistrationCollection.GuestsInfo:
      return cloneDeep(guestsInfoCollection);
    case RegistrationCollection.Email:
      return cloneDeep(emailInfoCollection);
    case RegistrationCollection.Phone:
      return cloneDeep(phoneInfoCollection);
    default:
      return undefined;
  }
};
