import React, { useEffect, useState } from 'react';
import Button from 'components/common/Button';
import {
  ContentWrap,
  ModalWrap,
  HeaderText,
  CloseIconWrap,
  LabelCheckboxWrap,
  FormFieldWrap,
  LabelText,
  LabelOptionWrap,
  LabelText2,
  ButtonWrapper,
  InputWrapper,
  OptionFieldsWrapper,
  OptionInputWrapper,
  QuantityInputWrapper,
  MonetaryInputWrapper,
} from './FieldDetailsModal.style';
import { IFieldDetailsModal } from './IFieldDetailsModal';
import Input from 'components/common/Input';
import { CrossIcon, PlusIcon } from 'public/assets/icons';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { composeValidators, required } from 'utils/validators';
import Form from 'components/common/Form';
import CheckBox from 'components/common/CheckBox';
import {
  IFormField,
  FormFieldType,
  ICheckboxField,
  IMultipleField,
} from 'components/DynamicFormComponents/FormField/FormField';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import ValidationError from 'components/ValidationError';
import { convertToCents } from 'utils/helpers';

export const FieldDetailsModal = ({
  open,
  onClose,
  onSubmit,
  field,
  metadata,
}: IFieldDetailsModal) => {
  const { t } = useTranslation();

  // To translate the value in text input
  const translatedField = {
    ...field,
    label: t(`event:${field?.name}`, field?.name),
  };

  const handleSubmit = (field: IFormField) => {
    let baseField = { ...field, type: field.type } as IFormField;
    baseField.name = field.name ?? field.label;

    if (
      field.type === FormFieldType.Checkbox &&
      field.monetaryValue !== undefined
    ) {
      baseField = {
        ...baseField,
        monetaryValue: convertToCents(field.monetaryValue),
      } as ICheckboxField;
    }

    if (field.type === FormFieldType.MultipleOptions && field.options) {
      baseField = {
        ...baseField,
        options: field.options.map((option) => ({
          ...option,
          value: option.value || option.label,
          ...(option.monetaryValue !== undefined && {
            monetaryValue: convertToCents(option.monetaryValue),
          }),
        })),
      } as IMultipleField;
    }

    onSubmit(baseField);
    onClose();
  };

  return (
    open && (
      <ContentWrap onClick={onClose}>
        <ModalWrap onClick={(e) => e.stopPropagation()}>
          <HeaderText>{t('event:fieldDetails')}</HeaderText>

          <FinalForm
            initialValues={translatedField}
            mutators={{ ...arrayMutators }}
            onSubmit={(e: IFormField) => handleSubmit(e)}
            validate={(values) => {
              const errors: any = {};
              if (field.type === FormFieldType.MultipleOptions) {
                if (!values.options || values.options.length === 0) {
                  errors.options = t('event:atLeastOneOptionRequired');
                }
              }
              return errors;
            }}
            render={(formRenderProps: FormRenderProps) => {
              const { handleSubmit, submitFailed, errors } = formRenderProps;
              console.log('Errors: ', errors);

              return (
                <Form onSubmit={handleSubmit}>
                  <FormFieldWrap>
                    <Input
                      id="field-name"
                      name="label"
                      type="text"
                      label={t('event:fieldName')}
                      validate={composeValidators(
                        required('This field is required!')
                      )}
                    />

                    {metadata?.isPaymentField &&
                      field.type === FormFieldType.Checkbox && (
                        <>
                          <Field name="maxQuantity" type="number">
                            {(props) => (
                              <FormFieldWrap>
                                <LabelText>{t('event:maxQuantity')}</LabelText>
                                <Input {...props.input} type="number" />
                              </FormFieldWrap>
                            )}
                          </Field>

                          <Field name="monetaryValue" type="number">
                            {(props) => (
                              <FormFieldWrap>
                                <LabelText>
                                  {t('event:monetaryValue')}
                                </LabelText>
                                <Input {...props.input} type="number" />
                              </FormFieldWrap>
                            )}
                          </Field>

                          <Field name="alwaysChecked" type="checkbox">
                            {(props) => (
                              <LabelCheckboxWrap>
                                <CheckBox
                                  {...props.input}
                                  type="checkbox"
                                  checked={props.input.checked}
                                  height={10}
                                  width={10}
                                />
                                <LabelText>
                                  {t('event:required')}
                                </LabelText>
                              </LabelCheckboxWrap>
                            )}
                          </Field>
                        </>
                      )}

                    {field.type !== FormFieldType.Checkbox && (
                      <Field name="required" type="checkbox">
                        {(props) => (
                          <LabelCheckboxWrap>
                            <CheckBox
                              {...props.input}
                              type="checkbox"
                              checked={props.input.checked}
                              height={10}
                              width={10}
                            />
                            <LabelText>{t('event:required')}</LabelText>
                          </LabelCheckboxWrap>
                        )}
                      </Field>
                    )}

                    {field.type === FormFieldType.MultipleOptions && (
                      <Field name="isMultiSelect" type="checkbox">
                        {(props) => (
                          <LabelCheckboxWrap>
                            <CheckBox
                              {...props.input}
                              type="checkbox"
                              checked={props.input.checked}
                              height={10}
                              width={10}
                            />
                            <LabelText>
                              {t('event:allowMultipleChoices')}
                            </LabelText>
                          </LabelCheckboxWrap>
                        )}
                      </Field>
                    )}

                    {field.type === FormFieldType.DatePicker && (
                      <Field name="isRangedDate" type="checkbox">
                        {(props) => (
                          <LabelCheckboxWrap>
                            <CheckBox
                              {...props.input}
                              type="checkbox"
                              checked={props.input.checked}
                              height={10}
                              width={10}
                            />
                            <LabelText>{t('event:isRangedDate')}</LabelText>
                          </LabelCheckboxWrap>
                        )}
                      </Field>
                    )}

                    {field.type === FormFieldType.MultipleOptions && (
                      <>
                        <LabelOptionWrap>
                          <LabelText2>{t('event:addOption')}</LabelText2>
                          <PlusIcon
                            onClick={() =>
                              formRenderProps.form.mutators.push(
                                'options',
                                undefined
                              )
                            }
                          />
                        </LabelOptionWrap>

                        <FieldArray name="options">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div
                                key={name}
                                style={{
                                  gap: 10,
                                  display: 'flex',
                                  marginBottom: 10,
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <OptionFieldsWrapper>
                                  <OptionInputWrapper>
                                    <Input
                                      id={`option-name-${index}`}
                                      name={`${name}.label`}
                                      type="text"
                                      label={t('event:optionLabel')}
                                      validate={composeValidators(
                                        required('This field is required!')
                                      )}
                                    />
                                  </OptionInputWrapper>

                                  {metadata?.isPaymentField && (
                                    <>
                                      <QuantityInputWrapper>
                                        <Input
                                          id={`option-max-quantity-${index}`}
                                          name={`${name}.maxQuantity`}
                                          type="number"
                                          label={t('event:maxQuantity')}
                                        />
                                      </QuantityInputWrapper>

                                      <MonetaryInputWrapper>
                                        <Input
                                          id={`option-monetary-value-${index}`}
                                          name={`${name}.monetaryValue`}
                                          type="number"
                                          label={t('event:monetaryValue')}
                                        />
                                      </MonetaryInputWrapper>
                                    </>
                                  )}
                                </OptionFieldsWrapper>

                                <span
                                  onClick={() => fields.remove(index)}
                                  style={{ cursor: 'pointer', marginTop: 15 }}
                                >
                                  <CrossIcon color="#8D8D8D" />
                                </span>
                              </div>
                            ))
                          }
                        </FieldArray>
                      </>
                    )}
                  </FormFieldWrap>
                  {submitFailed && typeof errors.options === 'string' && (
                    <div style={{ marginBottom: 15, textAlign: 'center' }}>
                      <ValidationError touched error={errors.options} />
                    </div>
                  )}
                  <ButtonWrapper>
                    <Button
                      variant="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      {t('settings:submitBtn')}
                    </Button>
                  </ButtonWrapper>
                </Form>
              );
            }}
          />

          <CloseIconWrap onClick={onClose}>
            <CrossIcon color="#8D8D8D" />
          </CloseIconWrap>
        </ModalWrap>
      </ContentWrap>
    )
  );
};
