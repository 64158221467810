import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'components/Drawer';
import { ICreateRegistrationFormDrawer } from './IRegistrationFormDrawer';
import API_NAME from 'services/api';
import {
  FieldCollection,
  IFieldCollection,
} from 'components/DynamicFormComponents/FieldCollection/FieldCollection';
import {
  getCollectionByEnum,
  RegistrationCollection,
} from './RegistrationCollections';
import Text from 'components/common/Text';
import {
  SectionHeader,
  SectionWrapper,
  Select,
  SelectIconWrap,
  SelectWrapper,
  SeparateSettingsHeader,
  TimeZoneText,
  ValidationErrorWrapper,
} from './CreateRegistrationFormDrawer.styles';
import { InputDescriptionField, LoadingComponent, Pill } from 'components';
import { Field } from 'react-final-form';
import ScheduleTime from 'components/ScheduleTime';
import { getDateInOriginalTimezone } from 'utils/date';
import moment, { Moment } from 'moment';
import { mapTimezoneOffsetToMinutes } from 'utils/time';
import { WorldIcon } from 'public/assets/icons';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import { format } from 'date-fns';
import momentTimezone from 'moment-timezone';
import { composeValidators, required } from 'utils/validators';
import { FieldDetailsModal } from 'components/Modals/FieldDetailsModal/FieldDetailsModal';
import RegistrationFormPreview from './RegistrationFormPreview';
import {
  FormFieldType,
  FormFieldTypeLabels,
  FormType,
  IFormField,
} from 'components/DynamicFormComponents/FormField/FormField';
import fetchApi from 'hooks/fetchApi';

interface RegistrationFormData {
  id: string;
  description: string;
  openingDate: number;
  closingDate: number;
  timezone: string;
  fieldCollections: IFieldCollection[];
  type: FormType.Registration;
}

const CreateRegistrationFormDrawer: React.FC<ICreateRegistrationFormDrawer> = ({
  open,
  setOpen,
  form,
  initialValues,
  changeFormStatus,
}) => {
  const { t } = useTranslation();
  const { action } = fetchApi();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [description, setDescription] = useState<string>('');
  const [fieldToEdit, setFieldToEdit] = useState<IFormField | null>(null);
  const [loading, setIsLoading] = useState(false);
  const [fieldCollections, setFieldCollections] = useState<IFieldCollection[]>(
    initialValues?.registrationForm?.fieldCollections || []
  );

  const [registrationStart, setRegistrationStart] = useState<Moment>(
    initialValues?.regStartDate ? moment(initialValues.regStartDate) : moment()
  );

  const [registrationEnd, setRegistrationEnd] = useState<Moment>(
    initialValues?.regEndDate
      ? moment(initialValues.regEndDate)
      : moment().add(1, 'hour')
  );

  const [timezone, setTimeZone] = useState<string>(
    initialValues?.regTimezone
      ? momentTimezone.tz(initialValues.regTimezone).format('Z')
      : format(new Date(), 'xxx')
  );
  const [previewData, setPreviewData] = useState<RegistrationFormData | null>(
    null
  );
  const [questionTemplates, setQuestionTemplates] = useState<Array<any>>([]);
  const eventId = initialValues?._id;
  const registrationForm = initialValues?.forms?.find(
    (el) => el?.type === FormType.Registration
  );
  const formId = registrationForm?.id;

  useEffect(() => {
    setQuestionTemplates(
      Object.values(FormFieldType)
        ?.filter((e) => FormFieldTypeLabels[e] !== FormFieldType?.SIGNATURE && FormFieldTypeLabels[e] !== FormFieldType?.Phone)
        ?.map((e) => e)
    );
  }, []);

  useEffect(() => {
    const fetchFormData = async () => {
      setIsLoading(true);
      try {
        const response = await action(API_NAME.GET_EVENT_FORMS, {
          params: { eventId, formId },
        });
        if (response?.data) {
          setRegistrationEnd(moment(response?.data?.closingDate));
          setRegistrationStart(moment(response?.data?.openingDate));
          setFieldCollections(response?.data?.fieldCollections);
          setDescription(response?.data?.description);
        }
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    if (open && eventId && formId) {
      fetchFormData();
    }
  }, [open, eventId, formId, action]);

  const handleClose = () => {
    if (isPreviewMode) {
      setIsPreviewMode(false);
      setPreviewData(null);
    } else {
      setOpen(false);
    }
  };

  const handleAddCollection = (collectionEnum: RegistrationCollection) => {    
    const collection = getCollectionByEnum(collectionEnum);
    if (collection.fields.length === 1 && collection.mustEditBeforeSubmit) {
      setFieldToEdit(collection.fields[0]);
      setIsModalOpen(true);
    } else {
      setFieldCollections((prev) => [...prev, collection]);
    }
  };

  const handleAddCustomField = (type: FormFieldType) => {
    // console.log();
    
    const baseFieldConfig = {
      type,
      name: '',
      label: '',
      required: false,
    };

    const specificFieldConfigs = {
      [FormFieldType.MultipleOptions]: {
        ...baseFieldConfig,
        options: [],
      },
    };

    setIsModalOpen(true);
    setFieldToEdit(specificFieldConfigs[type] || baseFieldConfig);
  };

  const handleRemoveCollection = (collectionIndex: number) => {
    setFieldCollections((prevCollections) =>
      prevCollections.filter((_, index) => index !== collectionIndex)
    );
  };

  const handleRemoveField = (collectionIndex: number, fieldIndex: number) => {
    setFieldCollections((prevCollections) => {
      const newCollections = [...prevCollections];
      newCollections[collectionIndex].fields.splice(fieldIndex, 1);
      if (newCollections[collectionIndex].fields.length === 0) {
        newCollections.splice(collectionIndex, 1);
      }
      return newCollections;
    });
  };

  const handleEditField = (collectionIndex: number, fieldIndex: number) => {
    const field = fieldCollections[collectionIndex].fields[fieldIndex];
    setFieldToEdit(field);
    setIsModalOpen(true);
  };

  const updateFieldInCollections = (
    prevCollections: IFieldCollection[],
    fieldToEdit: IFormField | null,
    updatedField: IFormField
  ): IFieldCollection[] => {
    const updatedCollections = [...prevCollections];

    // Update field if editing an existing field
    if (fieldToEdit) {
      // Find collection containing the field
      const existingCollectionIndex = updatedCollections.findIndex(
        (collection) => collection.fields.includes(fieldToEdit)
      );

      // Update field if collection found
      if (existingCollectionIndex !== -1) {
        const targetCollection = updatedCollections[existingCollectionIndex];
        const fieldIndex = targetCollection.fields.indexOf(fieldToEdit);

        targetCollection.fields[fieldIndex] = updatedField;
        return updatedCollections;
      }

      // If not found in existing collections, check predefined collections
      const predefinedCollection = Object.values(RegistrationCollection).find(
        (collectionEnum) => {
          const collection = getCollectionByEnum(collectionEnum);
          return (
            collection.mustEditBeforeSubmit &&
            collection?.fields.some((f) => f.name === fieldToEdit.name)
          );
        }
      );

      if (predefinedCollection) {
        const collection = getCollectionByEnum(predefinedCollection);
        if (collection) {
          const fieldIndex = collection.fields.findIndex(
            (f) => f.name === fieldToEdit.name
          );
          if (fieldIndex !== -1) {
            collection.fields[fieldIndex] = updatedField;
            updatedCollections.push(collection);
            return updatedCollections;
          }
        }
      }
    }

    // Add new collection with updated field if no match found
    return [...updatedCollections, { fields: [updatedField] }];
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFieldToEdit(null);
  };

  const handleModalSubmit = (field: IFormField) => {
    setFieldCollections((prevCollections) =>
      updateFieldInCollections(prevCollections, fieldToEdit, field)
    );
    handleCloseModal();
  };

  const addedCollections = fieldCollections.map(({ name }) => name);
  const availableCollections = Object.values(RegistrationCollection).filter(
    (collectionEnum) => !addedCollections.includes(collectionEnum)
  );

  const getTimezone = useMemo(() => {
    const minimalTimeOne = minimalTimezoneSet.filter(
      (d) => d?.offset === timezone
    );
    if (!minimalTimeOne.length) return '';

    const { offset, tzCode, label } = minimalTimeOne[0];
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);

    return isAbbrValid ? `(GMT${offset}) ${tzAbbrMoment}` : label;
  }, [timezone]);

  const updateTimezone = (offset: string) => {
    const updatedStartMoment = moment(registrationStart).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );
    const updatedEndMoment = moment(registrationEnd).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );

    setRegistrationStart(updatedStartMoment);
    setRegistrationEnd(updatedEndMoment);
    form.change('regStartDate', updatedStartMoment.valueOf());
    form.change('regEndDate', updatedEndMoment.valueOf());
    form.change('regTimezone', offset);
  };

  const isRegistrationFormValid = () => {
    if (fieldCollections.length === 0) {
      return false;
    }
    return true;
  };

  const handlePreview = () => {
    if (!isRegistrationFormValid()) return;

    const formData: RegistrationFormData = {
      id: formId,
      description: form.getState().values.regDescription,
      openingDate: registrationStart.valueOf(),
      closingDate: registrationEnd.valueOf(),
      timezone: timezone,
      fieldCollections: fieldCollections,
      type: FormType.Registration,
    };
    setPreviewData(formData);
    setIsPreviewMode(true);
  };

  const handleSave = () => {
    if (previewData) {
      form.change('registrationForm', previewData);
      changeFormStatus && changeFormStatus(true);
      setOpen(false);
      setIsPreviewMode(false);
      setPreviewData(null);
    }
  };

  useEffect(() => {
    if (initialValues) {
      if (initialValues.regStartDate) {
        setRegistrationStart(moment(initialValues.regStartDate));
        form.change('regStartDate', initialValues.regStartDate);
      }
      if (initialValues.regEndDate) {
        setRegistrationEnd(moment(initialValues.regEndDate));
        form.change('regEndDate', initialValues.regEndDate);
      }
      if (initialValues.regTimezone) {
        setTimeZone(initialValues.regTimezone);
        form.change('regTimezone', initialValues.regTimezone);
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (registrationStart.isAfter(registrationEnd)) {
      const newEndDate = moment(registrationStart).add(1, 'hour');
      setRegistrationEnd(newEndDate);
      form.change('regEndDate', newEndDate.valueOf());
    }
  }, [registrationStart, registrationEnd, form]);

  return (
    <Drawer
      open={open}
      headerText={
        isPreviewMode ? t('event:summaryForm') : t('event:registrationForm')
      }
      onIconClick={handleClose}
      onDrawerClose={handleClose}
      submitText={
        isPreviewMode ? t('editUserProfile:save') : t('event:preview')
      }
      onSubmit={isPreviewMode ? handleSave : handlePreview}
    >
      {loading ? (
        <LoadingComponent
          fullWidth
          loaderSize={50}
          color="#C2C2C2"
          variant="spin"
        />
      ) : isPreviewMode && previewData ? (
        <RegistrationFormPreview
          description={previewData.description}
          startDate={moment(previewData.openingDate)}
          endDate={moment(previewData.closingDate)}
          timezone={previewData.timezone}
          fieldCollections={previewData.fieldCollections}
        />
      ) : (
        <>
          <SectionWrapper pt={1}>
            <InputDescriptionField
              label={t('event:descriptionOptional')}
              name="regDescription"
              initialValue={description}
              showCharactersNumber
              maxLength={3000}
              height={100}
              validate={composeValidators(
                required(t('error:thisFieldIsRequired'))
              )}
            />
          </SectionWrapper>

          <SectionWrapper pt={16.5} pb={16.5}>
            <SectionHeader>{t('event:registrationDates')}</SectionHeader>

            <Field name="regStartDate">
              {({ input }) => (
                <ScheduleTime
                  selectedDate={getDateInOriginalTimezone(registrationStart)}
                  headerText={t('timePicker:start')}
                  onScheduleTimeChange={(value) => {
                    const updatedMoment = moment(value).utcOffset(
                      mapTimezoneOffsetToMinutes(timezone),
                      true
                    );
                    setRegistrationStart(updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={new Date()}
                />
              )}
            </Field>

            <Field name="regEndDate">
              {({ input }) => (
                <ScheduleTime
                  selectedDate={getDateInOriginalTimezone(registrationEnd)}
                  headerText={t('timePicker:end')}
                  onScheduleTimeChange={(value) => {
                    const updatedMoment = moment(value).utcOffset(
                      mapTimezoneOffsetToMinutes(timezone),
                      true
                    );
                    setRegistrationEnd(updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={registrationStart.toDate() || new Date()}
                />
              )}
            </Field>

            <SeparateSettingsHeader mb={4} mt={16}>
              {t('timePicker:timezone')}
            </SeparateSettingsHeader>

            <Field name="regTimeZone">
              {() => {
                return (
                  <SelectWrapper>
                    <SelectIconWrap>
                      <WorldIcon />
                    </SelectIconWrap>
                    <TimeZoneText>{getTimezone}</TimeZoneText>
                    <Select
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setTimeZone(e.target.value);
                        updateTimezone(e.target.value);
                      }}
                      value={timezone}
                    >
                      {minimalTimezoneSet.map((tz, index) => (
                        <option value={tz.offset} key={index}>
                          {tz.label}
                        </option>
                      ))}
                    </Select>
                  </SelectWrapper>
                );
              }}
            </Field>
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader>{t('event:selectTemplates')}</SectionHeader>
            {availableCollections.map((collectionEnum) => (
              <Pill
                text={'+' + t(`event:${collectionEnum}`)}
                onClick={() => handleAddCollection(collectionEnum)}
                fontSize={12}
                margin="0 10px 8px 0"
              />
            ))}
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader>{t('event:addQuestion')}</SectionHeader>
            {questionTemplates &&
              questionTemplates?.map((type) => (
                <Pill
                  key={type}
                  text={t(`event:${FormFieldTypeLabels[type]}`)}
                  onClick={() => handleAddCustomField(type)}
                  fontSize={12}
                  margin="0 10px 8px 0"
                />
              ))}
          </SectionWrapper>

          <FieldDetailsModal
            open={isModalOpen}
            field={fieldToEdit}
            onClose={handleCloseModal}
            onSubmit={handleModalSubmit}
          />

          <SectionWrapper>
            <>
              <SectionHeader>{t('event:questions')}</SectionHeader>
              {fieldCollections.map((collection, collectionIndex) => (
                <FieldCollection
                  key={`${collection.name}-${collectionIndex}`}
                  collection={collection}
                  disabled={true}
                  onRemoveCollection={() =>
                    handleRemoveCollection(collectionIndex)
                  }
                  onRemoveField={(fieldIndex) =>
                    handleRemoveField(collectionIndex, fieldIndex)
                  }
                  onEditField={(fieldIndex) =>
                    handleEditField(collectionIndex, fieldIndex)
                  }
                  isLastCollection={
                    collectionIndex === fieldCollections?.length - 1
                  }
                />
              ))}
              {fieldCollections.length === 0 && (
                <ValidationErrorWrapper
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    fontSize: '15px',
                    textAlign: 'center',
                  }}
                >
                  {t('event:addFieldCollectionToContinue')}
                </ValidationErrorWrapper>
              )}
            </>
          </SectionWrapper>
        </>
      )}
    </Drawer>
  );
};

export default CreateRegistrationFormDrawer;
